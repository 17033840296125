<template>
    <div class="admin-content">
        <div class="tit-wrap1">
            <h3>지원자 하위정보</h3>
            <div class="btn-wrap">
                <button type="button" class="btn-box gray">닫기</button>
                <button type="button" class="btn-box green">인쇄</button>	
            </div>
        </div>

        <div class="table-wrap table-list">
            <div class="table-box">
                <table>
                    <colgroup>
                        <col width="8%">
                        <col width="17%">
                        <col width="8%">
                        <col width="17%">
                        <col width="8%">
                        <col width="17%">
                        <col width="8%">
                        <col width="17%">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th col="지원자번호">지원자번호</th>
                            <td>2022-11011-1111</td>
                            <th col="수험번호">수험번호</th>
                            <td>2022-11011-1111</td>
                            <th col="성명">성명</th>
                            <td>홍길동</td>
                            <th col="지원분야">지원분야</th>
                            <td>리츠</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div>
            <div class="sub-cate-wrap2 mt50">
                <ul>
                    <li class="on"><a href="#" title="자기소개서">자기소개서</a></li>
                    <li><a href="#" title="경력">경력</a></li>
                    <li><a href="#" title="학력">학력</a></li>
                    <li><a href="#" title="병역">병역</a></li>
                    <li><a href="#" title="자격증">자격증</a></li>
                    <li><a href="#" title="외국어">외국어</a></li>
                    <li><a href="#" title="첨부파일">첨부파일</a></li>
                </ul>
            </div>

            <div class="table-wrap table-list mt70">
                <div class="table-box">
                    <table>
                        <colgroup>
                            <col width="15%">
                            <col width="85%">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th col="지원동기">지원동기</th>
                                <td>지원동기</td>
                            </tr>
                            <tr>
                                <th col="성격(장,단점) 및 생활신조">성격(장,단점) 및 생활신조</th>
                                <td>성격(장,단점) 및 생활신조</td>
                            </tr>
                            <tr>
                                <th col="경력사항 또는 성장과정">경력사항 또는 성장과정</th>
                                <td>경력사항 또는 성장과정</td>
                            </tr>
                            <tr>
                                <th col="희망업무">희망업무</th>
                                <td>희망업무</td>
                            </tr>
                            <tr>
                                <th col="입사 후 포부">입사 후 포부</th>
                                <td>입사 후 포부</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        
        <div>
            <div class="table-wrap table-list mt70">
                <div class="table-box">
                    <table>
                        <colgroup>
                            <col width="8%">
                            <col width="17%">
                            <col width="8%">
                            <col width="17%">
                            <col width="8%">
                            <col width="17%">
                            <col width="8%">
                            <col width="17%">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th col="회사명">회사명</th>
                                <td>회사명</td>
                                <th col="소재지">소재지</th>
                                <td>소재지</td>
                                <th col="부서명">부서명</th>
                                <td>부서명</td>
                                <th col="직위">직위</th>
                                <td>직위</td>
                            </tr>
                            <tr>
                                <th col="기간">기간</th>
                                <td colspan="5">기간</td>
                                <th col="근무개월수">근무개월수</th>
                                <td>근무개월수</td>
                            </tr>
                            <tr>
                                <th col="업무내용">업무내용</th>
                                <td colspan="7">업무내용</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div>
            <div class="table-wrap mt70">
                <div class="table-box">
                    <table>
                        <colgroup>
                            <col width="10%">
                            <col width="20%">
                            <col width="10%">
                            <col width="10%">
                            <col width="10%">
                            <col width="10%">
                            <col width="10%">
                            <col width="10%">
                            <col width="10%">
                        </colgroup>
                        <thead>
                            <tr>
                                <th col="학교종류">학교종류</th>
                                <th col="학교명">학교명</th>
                                <th col="소재지">소재지</th>
                                <th col="전공">전공</th>
                                <th col="입학일">입학일</th>
                                <th col="졸업일">졸업일</th>
                                <th col="학위">학위</th>
                                <th col="학점">학점</th>
                                <th col="졸업구분">졸업구분</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>고등학교</td>
                                <td>부일전자디자인</td>
                                <td>부산</td>
                                <td>컴퓨터과</td>
                                <td>2004-03</td>
                                <td>2007-02</td>
                                <td></td>
                                <td></td>
                                <td>졸업</td>
                            </tr>
                            <tr>
                                <td>대학교</td>
                                <td>동아대학교</td>
                                <td>부산</td>
                                <td>경영학과</td>
                                <td>2011-03</td>
                                <td>2015-02</td>
                                <td>경영학사</td>
                                <td>4.15</td>
                                <td>졸업</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { reactive } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { recruitmentPassManagement } from '@/modules/recruit/REC_RC0400R.js';
import { useStore } from 'vuex';
import alertManager from '@/utils/alert-confirm-manager';
export default {
    components: {
        
    },
    setup() {

        // 스토어
        const store = useStore();
        
        // state
        const state = reactive({
            // 데이터 테이블 state
            dataTable: {

                // 공고 목록
                searchRecruitmentList: new Array(),

                // 지원분야 목록
                searchPartList: new Array(),

                // 전형구분 목록
                searchStepTypeList: [

                    { 'code' : 'RC004001', 'codeName' : '서류' }
                    , { 'code' : 'RC004002', 'codeName' : '인적성' }
                    , { 'code' : 'RC004003', 'codeName' : '면접1차' }
                    , { 'code' : 'RC004004', 'codeName' : '면접2차' }
                    , { 'code' : 'RC004005', 'codeName' : '면접3차' }
                    , { 'code' : 'RC004007', 'codeName' : '예비' }
                    , { 'code' : 'RC004008', 'codeName' : '최종' }
                ],

                // 합격자 관리 목록
                passUserManagementList: new Array(),

                // 전형절차 컬럼 목록
                column: new Array()
            },

            // 선택된 공고(검색)
            selectedSearchRecruitment: '',

            // 지원자명(검색)
            searchApplicantNm: '',

            // 선택한 지원분야(검색)
            selectedSearchPart: '',

            // 선택한 전형구분(검색)
            selectedSearchStepType: '',

            // 선택한 전형구분 합불타입
            searchPassType: 'pass',

            // 변경할 전형구분
            changeStepType: 'RC004001',

            // 변경할 전형구분 합불타입
            changePassType: 'Y',

            // 선택한 지원서
            selectedApplicant: new Array(),

            // 변경할 지원서
            changeApplicant: new Array()
        });

        // 메서드
        const methods = {

            // 채용 공고 조회
            getNotice: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true)

                recruitmentPassManagement.getNotice('RECCM001').then(result => {

                    state.dataTable.searchRecruitmentList = result.Table.rows

                }).finally(() => {

                    state.selectedSearchRecruitment = state.dataTable.searchRecruitmentList[0]['Seq']

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false)

                    methods.getNoticeOptions()
                });
            },

            // 지원 분야 조회
            getNoticeOptions: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true)

                recruitmentPassManagement.getNoticeOptions(state.selectedSearchRecruitment.toString()).then(result => {
                    
                    state.dataTable.searchPartList = []

                    let part =  result.Table.rows.filter(part => part.USE_YN === 'Y')
                    state.dataTable.searchPartList = part

                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false)

                    state.selectedSearchPart = ''

                    methods.getUserPassManagementList();
                });
            },

            // 합격자 관리 목록 조회
            getUserPassManagementList: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                let seq = state.selectedSearchRecruitment.toString()
                let applicantNm = state.searchApplicantNm
                let part = state.selectedSearchPart
                let passType = state.selectedSearchStepType

                recruitmentPassManagement.getUserPassManagementList(seq, applicantNm, part, passType).then(result => {

                    if(result.Table.columns[0].name !== 'COLUMN_FIELD') {
                        state.dataTable.passUserManagementList = result.Table.rows
                        state.dataTable.column = result.Table1.rows
                    }
                    else{
                        state.dataTable.passUserManagementList = []
                        state.dataTable.column = []
                    }

                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                });
            },

            // 합불 저장
            setApplicantPass: () => {

                if(state.selectedApplicant.length == 0 && state.changeApplicant.length == 0){
                    alertManager.alert.warning('선택된 지원자가 없습니다.')
                    return
                }

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                let applicant = ''

                if(state.selectedApplicant.length > 0){

                    let applicantList = new Array()

                    state.selectedApplicant.forEach(data =>
                        applicantList.push({

                            'APCT_SEQ': data.APCT_SEQ
                            , 'Seq': state.selectedSearchRecruitment
                            , 'EXAM_DIV_CD' : state.changeStepType
                            , 'PASS_YN': state.changePassType
                        })
                    )

                    applicant = JSON.stringify(applicantList)
                }
                else{

                    applicant = JSON.stringify(state.changeApplicant)
                }

                recruitmentPassManagement.setApplicantPass(applicant).then((response) => {

                    if(response.success) {
                        
                        alertManager.alert.success('저장 되었습니다.')

                        state.selectedApplicant = [] 
                        state.changeApplicant.length = []

                        // 목록 재조회
                        methods.getUserPassManagementList();
                    }
                    
                }).finally(() =>{

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                });
            },
        }

        // 이벤트 핸들러
        const eventHandler = {

            // 합불 변경 이벤트
            onApplicantPassTypeChange: (info, passYN, examDivCd) => {
                
                let changeApplicant = {

                    'APCT_SEQ': info.APCT_SEQ
                    , 'Seq': state.selectedSearchRecruitment
                    , 'EXAM_DIV_CD' : examDivCd
                    , 'PASS_YN': passYN
                }

                state.changeApplicant.push(changeApplicant)
            },

            onApplicantClick: (info) => {

                alertManager.alert.success(info)
            }
        }

        // Dom 객체 그려진 이후
        onMounted(() => {

            // 합격자 관리 목록 초기조회
            methods.getNotice();
        });

        return {
            state,
            recruitmentPassManagement,
            methods,
            eventHandler,
        }
    }
}
</script>